<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">学员统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学习轨迹详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div style="margin: 0 14px 20px">
          <span>姓名：{{ userName }}</span>
          <span style="margin: 0 40px">身份证号：{{ idcard }}</span>
          <span style="margin: 0 20px">电话：{{ mobile }}</span>
          <span style="margin: 0 20px">课程名称：{{ courseName }}</span>
        </div>
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="课件名称" class="searchboxItem ci-full">
              <span class="itemLabel">课件名称:</span>
              <el-input v-model="ruleForm.catalogName" type="text" size="small" placeholder="请输入课件名称" clearable />
            </div>
            <div title="开始学习时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">开始学习时间:</span>
              <el-date-picker v-model="ruleForm.startStudyTime" type="daterange" size="small" range-separator="至"
                value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </div>
            <div title="结束学习时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">结束学习时间:</span>
              <el-date-picker v-model="ruleForm.endStudyTime" type="daterange" range-separator="至" size="small"
                value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </div>
            <div class="df" style="padding-right: 45px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <!-- <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="BulkDownload()"
                >导出</el-button
              > -->
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="课件名称" align="left" show-overflow-tooltip minWidth="200" prop="catalogName" />
              <el-table-column label="视频总时长" align="left" show-overflow-tooltip prop="kpointDuration" width="140">
                <template slot-scope="scope">
                  {{ getTime(scope.row.kpointDuration) }}
                </template>
              </el-table-column>
              <el-table-column label="学习视频时长" align="left" show-overflow-tooltip prop="timeInterval" width="140">
                <template slot-scope="scope">
                  {{ getTime(scope.row.timeInterval) }}
                </template>
              </el-table-column>
              <el-table-column label="开始学习时间" align="left" show-overflow-tooltip prop="startTime" width="180">
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="结束学习时间" align="left" show-overflow-tooltip prop="createTime" width="180">
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog title="学员学习轨迹" :visible.sync="dialogDownload" width="50%" top="2%" center
      :before-close="closeDownloadTrainDocument">
      <div id="pdf-content" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
export default {
  name: "set/TrajectoryList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogDownload: false,
      userName: "",
      mobile: "",
      courseName: "",
      idcard: "",
      userId: "",
      projectCourseId: "",
      ruleForm: {
        catalogName: "",
        startStudyTime: [],
        endStudyTime: [],
      },
      Obj: {},
    };
  },
  created() {
    this.Obj = JSON.parse(this.$route.query.data);
    console.log(this.Obj);
    this.userName = this.Obj.userName;
    this.mobile = this.Obj.mobile;
    this.courseName = this.Obj.courseName;
    this.idcard = this.Obj.idcard;
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    init() {
      this.getTableHeight();
      this.getData(-1);
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        userId: this.$route.query.userId,
        projectCourseId: this.$route.query.projectCourseId,
      };
      if (this.ruleForm.catalogName) {
        params.catalogName = this.ruleForm.catalogName;
      }
      if (this.ruleForm.startStudyTime) {
        params.startStartDate = this.ruleForm.startStudyTime[0];
        params.startEndDate = this.ruleForm.startStudyTime[1];
      }
      if (this.ruleForm.endStudyTime) {
        params.createStartDate = this.ruleForm.endStudyTime[0];
        params.createEndDate = this.ruleForm.endStudyTime[1];
      }
      this.doFetch({
        url: "/gov/course/study/track/queryStudyTrackList",
        params,
        pageNum,
      });
    },
    BulkDownload() {
      const params = {
        userId: this.$route.query.userId,
        projectCourseId: this.$route.query.projectCourseId,
      };
      if (this.ruleForm.catalogName) {
        params.catalogName = this.ruleForm.catalogName;
      }
      if (this.ruleForm.startStudyTime) {
        params.startStartDate = this.ruleForm.startStudyTime[0];
        params.startEndDate = this.ruleForm.startStudyTime[1];
      }
      if (this.ruleForm.endStudyTime) {
        params.createStartDate = this.ruleForm.endStudyTime[0];
        params.createEndDate = this.ruleForm.endStudyTime[1];
      }
      this.$post("/gov/course/study/track/exportStudyTrackListPdf", params)
        .then((res) => {
          if (res.status == 0) {
            this.dialogDownload = true;
            this.$nextTick(() => {
              pdf.embed(res.data, "#pdf-content");
            });
          } else {
            that.$message.warning({ message: res.message, duration: 1500 });
          }
        })
        .catch(() => {
          return;
        });
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
        (m != "00" ? m + "分" : "") +
        (s != "00" ? s + "秒" : "") || ""
      );
    },
    /* 获取高度 */
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 60;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.operationControlCurr {
  >div {
    width: 100%;
    justify-content: space-between;
  }

  .el-form {
    display: flex;

    .el-form-item__label {
      padding: 0 8px 0 0 !important;
    }

    .el-form-item {
      margin-bottom: 0;
    }

    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}
</style>
